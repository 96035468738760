import styled from 'styled-components';

export const Container = styled.div`
  /* position: absolute; */
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 99999;

  img {
    height: 50px;
  }

  > svg {
    width: 24px;
    height: 24px;
    fill: #0065b3;
    cursor: pointer;
  }
`;

interface MenuOverlayContainerProps {
  isOpened: number;
  categoryOpened: number;
}

export const MenuOverlayContainer = styled.div<MenuOverlayContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);

  transition: transform 0.2s;
  transform: translate3d(-100%, 0, 0);

  ${({ isOpened }) => isOpened && 'transform: translate3d(0, 0, 0);'}

  > div {
    position: relative;
    width: 70%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    > a {
      /* logo */
      &:nth-of-type(1) {
        margin: 24px;
      }
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        width: 90%;
        font-weight: 500;
        margin: 4px 0;
      }
      /* ultimo com cor */
      &:nth-of-type(4) {
        color: #0065b3;
      }
    }

    > div {
      margin-top: 16px;
      width: 90%;

      > ul > li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin: 8px 0;

        > svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        > a {
          display: flex;
          align-items: center;

          > svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }

        > div {
          display: none;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.05);
          padding: 8px 16px;
          margin-top: 8px;

          > ul > li > a {
            display: block;
            margin: 8px 0;
          }
        }

        &:nth-of-type(${({ categoryOpened }) => categoryOpened}) > div {
          display: block;
        }
      }
    }
  }
`;
