import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { FaCubes, FaStore, FaShippingFast } from 'react-icons/fa';

// components
import About from './About';
import OurUnits from './OurUnits';
import Regions from './Regions';

// utils, services, hooks
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

// styles
import { Container, TabItem, LoaderContainer } from './styles';

// interfaces
interface InstitutionalState {
  about: {
    mainImage: string;
    mainTitle: string;
    mainText: string;
    mission: string;
    vision: string;
    values: string;
    images: string[];
  };
  ourUnits: {
    image: string;
    title: string;
    address: string;
    contact: string;
    map: string;
  }[];
  regions: {
    description: string;
    cities: {
      name: string;
      image: string;
      period: string;
    }[];
  };
}

const Institutional: React.FC = () => {
  const [state, setState] = useState<InstitutionalState | undefined>();

  const [isLoading, setIsLoading] = useState(true);

  const { pathname } = useLocation();
  const { addToast } = useToast();

  const getData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await api.get('/pages/institutional');

      if (response.status === 200) {
        setState(response.data);
      } else throw new Error('Erro ao recuperar informações');
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro ao recuperar informações',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <Container>
      <div>
        <div>
          <ul>
            <TabItem active={Number(pathname.includes('quem-somos'))}>
              <Link to="/quem-somos">
                <FaCubes /> <span>QUEM SOMOS</span>
              </Link>
            </TabItem>
            <TabItem active={Number(pathname.includes('nossas-unidades'))}>
              <Link to="/nossas-unidades">
                <FaStore /> <span>NOSSAS UNIDADES</span>
              </Link>
            </TabItem>
            <TabItem
              active={Number(pathname.includes('regioes-que-atendemos'))}
            >
              <Link to="/regioes-que-atendemos">
                <FaShippingFast /> <span>REGIÕES QUE ATENDEMOS</span>
              </Link>
            </TabItem>
          </ul>
        </div>
        {state && (
          <div>
            {pathname.includes('quem-somos') && <About about={state.about} />}
            {pathname.includes('nossas-unidades') && (
              <OurUnits ourUnits={state.ourUnits} />
            )}
            {pathname.includes('regioes-que-atendemos') && (
              <Regions regions={state.regions} />
            )}
          </div>
        )}
        {isLoading && (
          <LoaderContainer>
            <Loader color="#0065b3" width={48} type="ThreeDots" />
          </LoaderContainer>
        )}
      </div>
    </Container>
  );
};

export default Institutional;
