import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

interface ContainerProps {
  itemsCount: number;
  moveCount: number;
  itemsVisibleCount: number;
}

export const Container = styled.div<ContainerProps>`
  margin: 0 auto 48px;

  width: ${({ itemsVisibleCount }) =>
    itemsVisibleCount * 240 + (itemsVisibleCount - 1) * 16}px;

  > h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;

    position: relative;
    z-index: 1;

    &:before {
      border-top: 1px solid #000;
      content: '';
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    span {
      background: #f7f7f7;
      padding: 0 16px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    /* max-width: 100%; */
    position: relative;

    & > button {
      /* padding: 16px; */
      background-color: #fff;

      border-radius: 50%;
      width: 36px;
      height: 36px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      cursor: pointer;

      &:disabled {
        color: #ccc;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      @media (min-width: ${breakpoints.lg}) {
        width: 48px;
        height: 48px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    & > button:nth-of-type(1) {
      left: -48px;
    }
    & > button:nth-of-type(2) {
      right: -48px;
    }

    @media (min-width: ${breakpoints.lg}) {
      & > button:nth-of-type(1) {
        left: -64px;
      }
      & > button:nth-of-type(2) {
        right: -64px;
      }
    }

    > div {
      overflow: hidden;

      > div {
        width: ${({ itemsCount }) => itemsCount * 256}px;
        transition: transform 0.15s;
        transform: translate3d(-${({ moveCount }) => moveCount * 256}px, 0, 0);
        display: flex;
      }
    }
  }
`;
