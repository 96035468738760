import styled from 'styled-components';
import breakpoints from '../../../utils/breakpoints';

export const Container = styled.header`
  width: 100%;
  z-index: 998;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;

    img {
      height: 40px;
      margin-right: 16px;

      @media (min-width: ${breakpoints.lg}) {
        height: 50px;
      }
    }

    > div:nth-of-type(1) {
      width: 30%;

      @media (min-width: ${breakpoints.lg}) {
        width: 35%;
      }

      @media (min-width: ${breakpoints.xl}) {
        width: 40%;
      }
    }

    a {
      font-size: 14px;
      font-weight: 500;

      & + a {
        margin-left: 16px;
      }

      &:last-of-type {
        color: #0065b3;
      }
    }

    > div:nth-of-type(2) {
    }
  }
`;
