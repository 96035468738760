import React from 'react';

import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Container } from './styles';

interface PathProps {
  items: {
    link: string;
    name: string;
  }[];
}

const Path: React.FC<PathProps> = ({ items }) => {
  return (
    <Container>
      {items.map(({ link, name }, index) => (
        <React.Fragment key={link}>
          {index !== 0 && <MdKeyboardArrowRight />}
          <Link to={link}>{name}</Link>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default Path;
