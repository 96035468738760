const informations = {
  facebook: '/casadasembalagensoficial',
  facebookLink: 'https://www.facebook.com/casadasembalagensoficial',
  mercadoLivreLink: 'https://lista.mercadolivre.com.br/_CustId_620102454',
  instagram: '@casadasembalagensfranca',
  instagramLink: 'https://www.instagram.com/casadasembalagensfranca',
  telephone: '(016) 3711-3200',
  telephoneLink: 'https://api.whatsapp.com/send?phone=551637113200',
  email: 'atendimento@casem.com.br',
  emailLink: 'mailto:atendimento@casem.com.br',
};

export default informations;
