import styled from 'styled-components';

export const Container = styled.div<{ activePage: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px auto;

  svg {
    width: 20px;
    height: 20px;
    margin: 8px;
  }

  button {
    font-size: 1.1rem;
    padding: 8px;
    background-color: transparent;
  }

  button:nth-of-type(${({ activePage }) => activePage + 1}) {
    background-color: #0065b3;
    color: #fff;
  }
`;
