import styled from 'styled-components';

import breakpoints from '../../../utils/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > a:last-child {
    margin-right: auto !important;
  }

  > a {
    /* 2 */
    width: 48% !important;
    margin: 1% !important;

    /* 3 */
    @media (min-width: ${breakpoints.md}) {
      width: 31.33% !important;
      margin: 1% !important;
    }

    /* 4 */
    @media (min-width: ${breakpoints.lg}) {
      width: 23% !important;
      margin: 1% !important;
    }

    /* 5 */
    @media (min-width: ${breakpoints.xl}) {
      width: 18% !important;
      margin: 1% !important;
    }
  }
`;
