import React from 'react';
import { MdEventAvailable } from 'react-icons/md';
import { Helmet } from 'react-helmet';

import { Container, CityContainer } from './styles';
import informations from '../../../utils/informations';

interface RegionsProps {
  regions: {
    description: string;
    cities: {
      name: string;
      image: string;
      period: string;
    }[];
  };
}

const Regions: React.FC<RegionsProps> = ({ regions }) => {
  return (
    <Container>
      <Helmet>
        <title>Casa das Embalagens - Regiões que atendemos</title>
        <meta
          property="og:title"
          content="Casa das Embalagens - Regiões que atendemos"
        />
      </Helmet>
      <p>{regions.description}</p>
      {regions.cities.map(city => (
        <CityContainer key={city.name}>
          <h4>
            <span>{city.name}</span>
          </h4>
          <img src={city.image} alt={city.name} />
          <span>{city.period}</span>
          <a
            href={informations.telephoneLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdEventAvailable />
            Consulte as datas aqui!
          </a>
        </CityContainer>
      ))}
    </Container>
  );
};

export default Regions;
