import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ContainerProps {
  float: number;
  automargin: number;
}

export const Container = styled(Link)<ContainerProps>`
  background-color: #fff;
  width: 240px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;

  ${({ automargin: autoMargin }) =>
    autoMargin &&
    css`
      & + a {
        margin-left: 16px;
      }
    `}

  float: ${({ float }) => (float ? 'left' : 'none')};

  > div:first-child {
    position: relative;
    margin-bottom: 8px;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;

      padding-bottom: 8px;
      border-bottom: 1px solid #f2f2f2;
      object-fit: cover;
    }
  }

  > span {
    overflow-wrap: break-word;

    &:nth-of-type(1) {
      font-size: 14px;
      font-weight: 500;
      color: #a5a5a5;
      margin-bottom: 4px;
    }
    &:nth-of-type(2) {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  > div:last-child {
    margin-top: auto;

    display: flex;
    align-items: center;
    background-color: #0065b3;

    > div {
      padding: 8px;
      background-color: #8be32f;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        fill: #0065b3;
      }
    }

    span {
      flex: 1;
      color: #fff;
      font-size: 14px;
      text-align: left;
      margin-left: 16px;
    }
  }
`;
