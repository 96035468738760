import React from 'react';
import { Helmet } from 'react-helmet';
import { MdExplore, MdAccountBox } from 'react-icons/md';

import {
  Container,
  UnitContainer,
  UnitInfoContainer,
  MapContainer,
} from './styles';

interface OurUnitsProps {
  ourUnits: {
    image: string;
    title: string;
    address: string;
    contact: string;
    map: string;
  }[];
}

const OurUnits: React.FC<OurUnitsProps> = ({ ourUnits }) => {
  return (
    <Container>
      <Helmet>
        <title>Casa das Embalagens - Nossas Unidades</title>
        <meta
          property="og:title"
          content="Casa das Embalagens - Nossas Unidades"
        />
      </Helmet>
      {ourUnits.map(unit => (
        <UnitContainer key={unit.title}>
          <img src={unit.image} alt={unit.title} />
          <UnitInfoContainer>
            <h2>
              <span>{unit.title}</span>
            </h2>
            <div>
              <MdExplore />
              <p>{unit.address}</p>
            </div>
            <div>
              <MdAccountBox />
              <p>{unit.contact}</p>
            </div>
            <MapContainer dangerouslySetInnerHTML={{ __html: unit.map }} />
          </UnitInfoContainer>
        </UnitContainer>
      ))}
    </Container>
  );
};

export default OurUnits;
