import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <FiAlertCircle />
      <span>Página não encontrada!</span>
    </Container>
  );
};

export default NotFound;
