import React, { useState, useCallback, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Carousel } from 'react-responsive-carousel';
import Loader from 'react-loader-spinner';
import { Helmet } from 'react-helmet';

// components
import { FiAlertCircle } from 'react-icons/fi';
import ProductsCarousel from '../../components/ProductsCarousel';

// utils, services, hooks
import api from '../../services/api';

// styles
import {
  Container,
  PathContainer,
  ProductCard,
  RelatedsContainer,
  LoaderContainer,
  ErrorContainer,
} from './styles';
import { useProductInfo } from '../../hooks/productInformation';

// interfaces
interface ProductState {
  product: {
    id: number;
    name: string;
    reference: string;
    description: string;
    images: string[];
    categoryId: number;
    categoryName: string;
    subCategoryId: number;
    subCategoryName: string;
  };
  relatedProducts: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
}

const Product: React.FC = () => {
  const [state, setState] = useState<ProductState | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { idProduct } = useParams<{ idProduct?: string }>();
  const { setProductInfo } = useProductInfo();

  const getData = useCallback(async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await api.get(`/pages/product/${idProduct}`);

      if (response.status === 200) {
        setState(response.data);
      } else if (response.status === 404) {
        setError('O produto que você está procurando não existe');
      } else {
        setError('Ocorreu um erro inesperado. Tente novamente mais tarde');
      }
    } finally {
      setIsLoading(false);
    }
  }, [idProduct]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (state?.product) {
      setProductInfo({ name: state.product.name, link: window.location.href });
    }

    return () => {
      setProductInfo(undefined);
    };
  }, [setProductInfo, state]);

  return (
    <Container>
      {state && (
        <>
          <Helmet>
            <title>Casa das Embalagens - {state.product.name}</title>
            <meta
              property="og:title"
              content={`Casa das Embalagens - ${state.product.name}`.slice(
                0,
                56,
              )}
            />
            <meta
              name="description"
              content={state.product.name.slice(0, 66)}
            />
            <meta
              property="og:description"
              content={state.product.name.slice(0, 156)}
            />
            {state.product.images[0] && (
              <meta property="og:image" content={state.product.images[0]} />
            )}
            <meta property="og:type" content="product" />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <PathContainer>
            <Link to="/">Página Inicial</Link>
            <MdKeyboardArrowRight />
            <Link to={`/categoria/${state.product.categoryId}`}>
              {state.product.categoryName}
            </Link>
            <MdKeyboardArrowRight />
            <Link
              to={`/categoria/${state.product.categoryId}/subcategoria/${state.product.subCategoryId}`}
            >
              {state.product.subCategoryName}
            </Link>
          </PathContainer>
          <ProductCard>
            <h1>{state.product.name}</h1>
            <span>Código: {state.product.reference || 'asdasdas'}</span>
            <div>
              <Carousel
                showArrows
                infiniteLoop
                transitionTime={500}
                showStatus={false}
                emulateTouch
              >
                {state.product.images.map((url, index) => (
                  <img
                    key={url}
                    src={url}
                    alt={`Imagem do carrosel #${index}`}
                  />
                ))}
              </Carousel>
            </div>
            <div>
              <h1>{state.product.name}</h1>
              <span>Código: {state.product.reference || 'asdasdas'}</span>
              <p>{state.product.description}</p>
            </div>
          </ProductCard>
          <RelatedsContainer>
            <ProductsCarousel
              name="Relacionados"
              items={state.relatedProducts}
            />
          </RelatedsContainer>
        </>
      )}
      {isLoading && (
        <LoaderContainer>
          <Loader color="#0065b3" width={48} type="ThreeDots" />
        </LoaderContainer>
      )}
      {error && (
        <ErrorContainer>
          <FiAlertCircle />
          <span>{error}</span>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default Product;
