import React from 'react';
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { GrFacebook, GrInstagram } from 'react-icons/gr';
import { Link } from 'react-router-dom';

// utils
import informations from '../../../utils/informations';

// styles
import { Container } from './styles';

// assets
import LogoCasem from '../../../assets/casem-branco.png';

const Footer: React.FC = () => {
  return (
    <Container>
      <div>
        <Link to="/">
          <img src={LogoCasem} alt="Logo CASEM" />
        </Link>
        <div>
          <h5>INSTITUCIONAL</h5>
          <Link to="/">
            <span>Página inicial</span>
          </Link>
          <Link to="/quem-somos">
            <span>Quem somos</span>
          </Link>
          <Link to="/nossas-unidades">
            <span>Nossas unidades</span>
          </Link>
          <Link to="/regioes-que-atendemos">
            <span>Regiões que antedemos</span>
          </Link>
        </div>
        <div>
          <h5>FALE CONOSCO</h5>
          <a
            href={informations.telephoneLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaWhatsapp /> {informations.telephone}
            </span>
          </a>
          <a
            href={informations.emailLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <MdEmail /> {informations.email}
            </span>
          </a>
        </div>
        <div>
          <h5>REDES SOCIAIS</h5>
          <a
            href={informations.instagramLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <GrInstagram /> {informations.instagram}
            </span>
          </a>
          <a
            href={informations.facebookLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <GrFacebook /> {informations.facebook}
            </span>
          </a>
        </div>
        <div>
          <h5>LOCALIZAÇÃO</h5>
          <div>
            <span>Loja 1</span>
            <span>
              Rua Líbero Badaró, 1644
              <br />
              Centro - Franca / SP
            </span>
          </div>
          <div>
            <span>Loja 2</span>
            <span>
              Rua Afonso Pena, 451
              <br />
              Centro - Franca / SP
            </span>
          </div>
        </div>
      </div>
      <div>
        Desenvolvido por{' '}
        <a
          href="http://liubergaria.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Helil Bergária
        </a>{' '}
        e{' '}
        <a href="http://ghlg.dev/" target="_blank" rel="noopener noreferrer">
          Gabriel Garcia
        </a>{' '}
      </div>
    </Container>
  );
};

export default Footer;
