import styled, { css } from 'styled-components';

import breakpoints from '../../utils/breakpoints';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  margin: 32px 0;

  > div {
    > div {
      &:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-bottom: 1px solid #ccc;

        ul {
          display: flex;

          li a {
            display: flex;
            align-items: center;
            padding: 16px 16px 8px;

            font-size: 1rem;
            font-weight: 500;

            svg {
              width: 28px;
              height: 28px;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
`;

export const TabItem = styled.li<{ active: number }>`
  span {
    display: none;

    @media (min-width: ${breakpoints.md}) {
      display: block;
    }
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        fill: #0065b3;
      }

      span {
        display: block;
      }

      border-bottom: 2px solid #0065b3;
    `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;
