import React from 'react';
import { DiMongodb } from 'react-icons/di';
import { AiOutlineClear } from 'react-icons/ai';
import { BsGiftFill, BsInboxFill, BsInbox, BsBagFill } from 'react-icons/bs';
import { BiDonateBlood, BiCoffeeTogo } from 'react-icons/bi';
import {
  FaGulp,
  FaShippingFast,
  FaShower,
  FaUtensils,
  FaToiletPaper,
  FaEnvira,
  FaTrash,
  FaShoppingBag,
  FaScroll,
} from 'react-icons/fa';
import {
  RiEBike2Line,
  RiEBike2Fill,
  RiCupLine,
  RiRestaurant2Line,
  RiShoppingBag3Line,
} from 'react-icons/ri';
import {
  GiBeachBucket,
  GiGlassCelebration,
  GiWineBottle,
} from 'react-icons/gi';
import { GrStatusUnknown } from 'react-icons/gr';
import { IoIosRestaurant, IoIosPrint } from 'react-icons/io';
import { SiBuymeacoffee } from 'react-icons/si';
import { HiShoppingBag } from 'react-icons/hi';

interface IconProps {
  icon: string;
}

export type IconTypes =
  | 'DiMongodb'
  | 'AiOutlineClear'
  | 'BsGiftFill'
  | 'BsInboxFill'
  | 'BsInbox'
  | 'BiDonateBlood'
  | 'BiCoffeeTogo'
  | 'FaGulp'
  | 'FaShippingFast'
  | 'FaShower'
  | 'FaUtensils'
  | 'FaToiletPaper'
  | 'FaEnvira'
  | 'FaTrash'
  | 'RiEBike2Line'
  | 'RiEBike2Fill'
  | 'RiCupLine'
  | 'RiRestaurant2Line'
  | 'GiBeachBucket'
  | 'GiWineBottle'
  | 'IoIosRestaurant'
  | 'IoIosPrint'
  | 'SiBuymeacoffee'
  | 'HiShoppingBag'
  | 'BsBagFill'
  | 'FaShoppingBag'
  | 'RiShoppingBag3Line'
  | 'GiGlassCelebration'
  | 'FaScroll';

export const iconsAvaible = [
  'DiMongodb',
  'AiOutlineClear',
  'BsGiftFill',
  'BsInboxFill',
  'BsInbox',
  'BiDonateBlood',
  'BiCoffeeTogo',
  'FaGulp',
  'FaShippingFast',
  'FaShower',
  'FaUtensils',
  'FaToiletPaper',
  'FaEnvira',
  'FaTrash',
  'RiEBike2Line',
  'RiEBike2Fill',
  'RiCupLine',
  'RiRestaurant2Line',
  'GiBeachBucket',
  'GiWineBottle',
  'IoIosRestaurant',
  'IoIosPrint',
  'SiBuymeacoffee',
  'HiShoppingBag',
  'BsBagFill',
  'FaShoppingBag',
  'RiShoppingBag3Line',
  'GiGlassCelebration',
  'FaScroll',
];

const Icon: React.FC<IconProps> = ({ icon }) => {
  switch (icon) {
    case 'DiMongodb':
      return <DiMongodb />;
    case 'AiOutlineClear':
      return <AiOutlineClear />;
    case 'BsGiftFill':
      return <BsGiftFill />;
    case 'BsInboxFill':
      return <BsInboxFill />;
    case 'BsInbox':
      return <BsInbox />;
    case 'BiDonateBlood':
      return <BiDonateBlood />;
    case 'BiCoffeeTogo':
      return <BiCoffeeTogo />;
    case 'FaGulp':
      return <FaGulp />;
    case 'FaShippingFast':
      return <FaShippingFast />;
    case 'FaShower':
      return <FaShower />;
    case 'FaUtensils':
      return <FaUtensils />;
    case 'FaToiletPaper':
      return <FaToiletPaper />;
    case 'FaEnvira':
      return <FaEnvira />;
    case 'FaTrash':
      return <FaTrash />;
    case 'RiEBike2Line':
      return <RiEBike2Line />;
    case 'RiEBike2Fill':
      return <RiEBike2Fill />;
    case 'RiCupLine':
      return <RiCupLine />;
    case 'RiRestaurant2Line':
      return <RiRestaurant2Line />;
    case 'GiBeachBucket':
      return <GiBeachBucket />;
    case 'GiWineBottle':
      return <GiWineBottle />;
    case 'IoIosRestaurant':
      return <IoIosRestaurant />;
    case 'IoIosPrint':
      return <IoIosPrint />;
    case 'SiBuymeacoffee':
      return <SiBuymeacoffee />;
    case 'HiShoppingBag':
      return <HiShoppingBag />;
    case 'BsBagFill':
      return <BsBagFill />;
    case 'FaShoppingBag':
      return <FaShoppingBag />;
    case 'RiShoppingBag3Line':
      return <RiShoppingBag3Line />;
    case 'GiGlassCelebration':
      return <GiGlassCelebration />;
    case 'FaScroll':
      return <FaScroll />;
    default:
      return <GrStatusUnknown />;
  }
};

export default Icon;
