import styled from 'styled-components';
import breakpoints from '../../../utils/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  max-width: 1232px;
  margin: 0 auto;
  padding: 0 16px;

  > p {
    margin: 32px auto;
    white-space: pre-wrap;
    width: 90%;

    @media (min-width: ${breakpoints.lg}) {
      width: 100%;
    }
  }
`;

export const CityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto 16px;

  @media (min-width: ${breakpoints.lg}) {
    width: 32%;
    margin: 0 2% 16px 0;

    &:nth-of-type(3n) {
      margin: 0 0 16px 0;
    }

    &:last-of-type {
      margin-right: auto;
    }
  }

  h4 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;

    &:before {
      border-top: 1px solid #000;
      content: '';
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    span {
      background: #fff;
      padding: 0 16px;
    }
  }

  img {
    width: 100%;
  }

  > span {
    width: 100%;
    padding: 16px;
    background-color: #ccc;
    text-align: center;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0065b3;
    padding: 16px;

    color: #fff;
    font-size: 1rem;

    svg {
      width: 28px;
      height: 28px;
      margin-right: 16px;
    }
  }
`;
