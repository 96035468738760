import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  MdMenu,
  MdSearch,
  MdClose,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { Link } from 'react-router-dom';

// components
import Icon from '../../../components/Icon';
import SearchInput from '../../../components/SearchInput';

// styles
import { Container, MenuOverlayContainer } from './styles';

// assets
import LogoCasem from '../../../assets/casem-azul.png';

interface Category {
  id: number;
  name: string;
  icon: string;
  subCategories: {
    id: number;
    name: string;
  }[];
}

interface HeaderMobileProps {
  categories: Category[];
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ categories }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [categoryOpened, setCategoryOpened] = useState(-1);
  const [fixed, setFixed] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!fixed && window.pageYOffset > 16) {
      setFixed(true);
    } else if (fixed && window.pageYOffset <= 16) {
      setFixed(false);
    }
  }, [fixed]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);

    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  const close = useCallback(() => setIsOpened(false), []);

  const menuClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!menuRef.current?.contains(event.target as Node)) close();
    },
    [close],
  );

  return (
    <Container style={{ position: fixed ? 'fixed' : 'relative' }}>
      <MdMenu onClick={() => setIsOpened(current => !current)} />

      {isSearchOpened ? (
        <SearchInput style={{ width: '50%' }} />
      ) : (
        <Link to="/" onClick={close}>
          <img src={LogoCasem} alt="Logo CASEM" />
        </Link>
      )}

      {isSearchOpened ? (
        <MdClose onClick={() => setIsSearchOpened(current => !current)} />
      ) : (
        <MdSearch onClick={() => setIsSearchOpened(current => !current)} />
      )}

      <MenuOverlayContainer
        isOpened={Number(isOpened)}
        categoryOpened={categoryOpened + 1}
        onClick={menuClick}
      >
        <div ref={menuRef}>
          <MdClose onClick={() => setIsOpened(current => !current)} />
          <Link to="/" onClick={close}>
            <img src={LogoCasem} alt="Logo CASEM" />
          </Link>
          <Link to="/quem-somos/" onClick={close}>
            Quem somos
          </Link>
          <Link to="/nossas-unidades" onClick={close}>
            Nossas unidades
          </Link>
          <Link to="/regioes-que-atendemos" onClick={close}>
            Regiões que atendemos
          </Link>
          <div>
            <ul>
              {categories.map((category, index) => (
                <li key={category.id}>
                  <Link to={`/categoria/${category.id}`} onClick={close}>
                    <Icon icon={category.icon} /> <span>{category.name}</span>
                  </Link>
                  {categoryOpened === index ? (
                    <MdKeyboardArrowUp onClick={() => setCategoryOpened(-1)} />
                  ) : (
                    <MdKeyboardArrowDown
                      onClick={() => setCategoryOpened(index)}
                    />
                  )}
                  <div>
                    <ul>
                      {category.subCategories.map(subCategory => (
                        <li key={subCategory.id}>
                          <Link
                            to={`/categoria/${category.id}/subcategoria/${subCategory.id}`}
                            onClick={close}
                          >
                            {subCategory.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </MenuOverlayContainer>
    </Container>
  );
};

export default HeaderMobile;
