import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

export const Container = styled.div``;

export const CarouselContainer = styled.div`
  width: 100%;
  background-color: #fff;

  > div {
    width: 100%;
    margin: 0 auto;

    a {
      display: block;
    }
  }
`;

export const SocialMedias = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }

  img {
    max-width: 48px;
  }

  a > div {
    width: 280px;
    display: flex;
    align-items: center;
    padding: 16px;

    & + div {
      margin-left: 32px;
    }

    div {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        &:nth-child(1) {
          font-size: 14px;
        }
        &:nth-child(2) {
          font-size: 14px;
          font-weight: 500;
          margin-top: 4px;
        }
      }
    }
  }
`;

export const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 32px auto;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;
