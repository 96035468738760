import React, { createContext, useState, useContext } from 'react';

// interfaces
interface ProductInfoState {
  name: string;
  link: string;
}

interface ProductInfoData {
  product?: ProductInfoState;
  setProductInfo: (info: ProductInfoState | undefined) => void;
}

const ProductInfoContext = createContext<ProductInfoData>(
  {} as ProductInfoData,
);

export const ProductInfoProvider: React.FC = ({ children }) => {
  const [productInfo, setProductInfo] = useState<ProductInfoState>();

  return (
    <ProductInfoContext.Provider
      value={{ product: productInfo, setProductInfo }}
    >
      {children}
    </ProductInfoContext.Provider>
  );
};

export const useProductInfo = (): ProductInfoData => {
  const context = useContext(ProductInfoContext);

  if (!context) {
    throw new Error('useProductInfo must be used within a ProductInfoProvider');
  }

  return context;
};
