import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import GlobalStyle from './styles/global';

import Routes from './routes';

import AppProvider from './hooks';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="pt-BR" />
        <meta property="og:site_name" content="Casa das Embalagens" />
      </Helmet>

      <AppProvider>
        <Routes />
      </AppProvider>

      <GlobalStyle />
    </BrowserRouter>
  );
};

export default App;
