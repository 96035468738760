import React, { useRef, useEffect, useState, useCallback } from 'react';
import { GrFacebook, GrInstagram } from 'react-icons/gr';
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// components
import HeaderFixed from '../HeaderFixed';
import SearchInput from '../../../components/SearchInput';
import Icon from '../../../components/Icon';

// utils, services, hooks
import informations from '../../../utils/informations';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

// styles
import { Container, Menu, TopBar, Middle } from './styles';

// assets
import LogoCasa from '../../../assets/casa-azul.png';
import LogoCasem from '../../../assets/casem-azul.png';
import HeaderMobile from '../HeaderMobile';

// interfaces
interface HeaderProps {
  setContainerHeight: React.Dispatch<React.SetStateAction<number>>;
}

interface Category {
  id: number;
  name: string;
  icon: string;
  subCategories: {
    id: number;
    name: string;
  }[];
}

const Header: React.FC<HeaderProps> = ({ setContainerHeight }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { addToast } = useToast();

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('/pages/header');

      if (response.status === 200) {
        setCategories(response.data);
      } else throw new Error('Erro ao buscar categorias');
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar categorias',
      });
    }
  }, [addToast]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const content = entry.contentRect;

        if (windowWidth <= 992) setContainerHeight(120);
        else setContainerHeight(content.height);
      });
    });

    const elementToBeObserved = ref.current;

    resizeObserver.observe(elementToBeObserved as Element);

    return () => {
      resizeObserver.unobserve(elementToBeObserved as Element);
    };
  }, [ref, setContainerHeight, windowWidth]);

  useEffect(() => {
    const handler = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <>
      <Container ref={ref}>
        <TopBar>
          <div>
            <div>
              <a
                href={informations.facebookLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GrFacebook />
              </a>
              <a
                href={informations.instagramLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GrInstagram />
              </a>
              <a
                href={informations.telephoneLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
            </div>
            <span>Seja bem-vindo à Casa das Embalagens!</span>
            <a
              href={informations.telephoneLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
              <span>{informations.telephone}</span>
            </a>
            <a
              href={informations.emailLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdEmail />
              <span>{informations.email}</span>
            </a>
          </div>
        </TopBar>
        {windowWidth <= 992 ? (
          <HeaderMobile categories={categories} />
        ) : (
          <>
            <Middle>
              <div>
                <Link to="/">
                  <img src={LogoCasem} alt="Logo CASEM" />
                </Link>
                <div>
                  <div>
                    <SearchInput />
                  </div>
                  <div>
                    <Link to="/quem-somos">Quem somos</Link>
                    <Link to="/nossas-unidades">Nossas unidades</Link>
                    <Link to="/regioes-que-atendemos">
                      Regiões que atendemos
                    </Link>
                  </div>
                </div>
                <Link to="/">
                  <img src={LogoCasa} alt="Logo Casa das Emabalagens" />
                </Link>
              </div>
            </Middle>
            <Menu>
              <div>
                <ul>
                  {categories.map(category => (
                    <li key={category.id}>
                      <Link to={`/categoria/${category.id}`}>
                        <Icon icon={category.icon} /> {category.name}
                      </Link>
                      <div>
                        <ul>
                          {category.subCategories.map(subCategory => (
                            <li key={subCategory.id}>
                              <Link
                                to={`/categoria/${category.id}/subcategoria/${subCategory.id}`}
                              >
                                {subCategory.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Menu>
          </>
        )}
      </Container>
      {windowWidth > 992 && <HeaderFixed />}
    </>
  );
};

export default Header;
