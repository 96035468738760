import styled, { css } from 'styled-components';

export const WhatsAppContainer = styled.a`
  position: fixed;
  right: 16px;
  bottom: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 50%;

  background-color: #4ac959;
  color: #fff;

  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  z-index: 9998;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const UpContainer = styled.button<{ show: number }>`
  position: fixed;
  right: 24px;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;

  background-color: #fff;
  color: #000;

  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  z-index: 9998;

  transform: scale(0);
  transform-origin: right bottom;

  transition: transform 0.25s;

  ${({ show }) =>
    show &&
    css`
      transform: scale(1);
    `}

  svg {
    width: 36px;
    height: 36px;
  }
`;
