import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;

  svg {
    width: 16px;
    height: 16px;
    margin: 0 8px;
  }

  a {
    &:last-of-type {
      color: #0065b3;
      font-weight: 500;
    }
  }
`;
