import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocus: number;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 2.5rem;
  min-width: 240px;
  border-width: 2px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 5px;

  transition: border-color 0.2s;

  input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    padding: 16px;
  }

  svg {
    fill: #cccccc;
    position: absolute;

    width: 1.5rem;
    height: 1.5rem;

    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;
    font-size: 1.25rem;
  }

  ${({ isFocus }) =>
    isFocus &&
    css`
      border-color: #0065b3;

      svg {
        fill: #0065b3;
      }
    `}
`;
