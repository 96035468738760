import React from 'react';
import { Helmet } from 'react-helmet';

import {
  Container,
  CompanyContainer,
  MissionVisionValuesContainer,
  PhotosContainer,
} from './styles';

interface AboutProps {
  about: {
    mainImage: string;
    mainTitle: string;
    mainText: string;
    mission: string;
    vision: string;
    values: string;
    images: string[];
  };
}

const About: React.FC<AboutProps> = ({ about }) => {
  return (
    <Container>
      <Helmet>
        <title>Casa das Embalagens - Quem Somos</title>
        <meta property="og:title" content="Casa das Embalagens - Quem Somos" />
      </Helmet>
      <CompanyContainer>
        <div>
          <img src={about.mainImage} alt={about.mainTitle} />
          <div>
            <h2>
              <span>{about.mainTitle}</span>
            </h2>
            <p>{about.mainText}</p>
          </div>
        </div>
      </CompanyContainer>
      <MissionVisionValuesContainer>
        <div>
          <div>
            <h4>
              <span>Missão</span>
            </h4>
            <p>{about.mission}</p>
          </div>
          <div>
            <h4>
              <span>Visão</span>
            </h4>
            <p>{about.vision}</p>
          </div>
          <div>
            <h4>
              <span>Valores</span>
            </h4>
            <p>{about.values}</p>
          </div>
        </div>
      </MissionVisionValuesContainer>
      <PhotosContainer>
        <div>
          <h3>
            <span>Fotos</span>
          </h3>
          <div>
            {about.images.map(url => (
              <img key={url} src={url} alt="Foto Quem Somos" />
            ))}
          </div>
        </div>
      </PhotosContainer>
    </Container>
  );
};

export default About;
