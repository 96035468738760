import React, { useState } from 'react';

// components
import Header from './Header';
import Footer from './Footer';
import GlobalElements from './GlobalElements';

// styles
import { Container } from './styles';

const Main: React.FC = ({ children }) => {
  const [containerHeight, setContainerHeight] = useState(0);

  return (
    <Container>
      <Header setContainerHeight={setContainerHeight} />
      <main style={{ paddingTop: containerHeight }}>{children}</main>
      <Footer />
      <GlobalElements />
    </Container>
  );
};

export default Main;
