import styled from 'styled-components';
import { transparentize } from 'polished';
import breakpoints from '../../utils/breakpoints';

export const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const PathContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;

  svg {
    width: 16px;
    height: 16px;
    margin: 0 8px;
  }

  a {
    &:last-of-type {
      color: #0065b3;
      font-weight: 500;
    }
  }
`;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 32px;
  padding: 32px;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 16px;
  }

  h1 {
    font-size: 1.75rem;
  }

  span {
    color: #a5a5a5;
    font-size: 1rem;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  > div {
    /* imagens */
    &:nth-of-type(1) {
      width: 100%;
      /* min-height: 100px; */
      /* background-color: brown; */

      @media (min-width: ${breakpoints.lg}) {
        width: 33%;

        > div {
          position: sticky;
          top: 128px;
        }
      }
    }

    /* informações */
    &:nth-of-type(2) {
      width: 100%;
      /* background-color: blue; */

      @media (min-width: ${breakpoints.lg}) {
        width: 65%;
      }

      p {
        white-space: pre-wrap;
        font-size: 1rem;
      }
    }
  }

  /* Alterar posição do título e referência */
  > div {
    & > h1,
    & > span {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    & > h1,
    & > span {
      display: none;
    }

    > div {
      & > h1,
      & > span {
        display: block;
      }
    }
  }
`;

export const RelatedsContainer = styled.div``;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  margin: 16px auto;
  background: ${transparentize(0.8, '#0065b3')};
  padding: 32px 64px;
  border-radius: 5px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);

  color: #0a2d7a;

  svg {
    width: 36px;
    height: 36px;
    margin-right: 24px;
  }

  span {
    font-size: 1.2rem;
  }
`;
