import React, { useMemo } from 'react';
import { MdAdd } from 'react-icons/md';

// assets
import NoImage from '../../assets/no-image.jpg';

// styles
import { Container } from './styles';

interface ProductResumeProps {
  id: number;
  imageUrl?: string;
  name: string;
  subCategoryId: number;
  subCategoryName: string;
  float?: boolean;
  autoMargin?: boolean;
  margin?: string;
  width?: string;
}

const ProductResume: React.FC<ProductResumeProps> = ({
  id,
  imageUrl,
  name,
  subCategoryName,
  float = false,
  autoMargin = false,
  margin,
  width,
}) => {
  const style = useMemo(() => {
    const obj: any = {};

    if (margin) {
      obj.marginLeft = margin;
      obj.marginRight = margin;
    }

    if (width) obj.width = width;

    return obj;
  }, [margin, width]);

  return (
    <Container
      float={Number(float)}
      automargin={Number(autoMargin)}
      style={style}
      to={`/produto/${id}`}
    >
      <div>
        <img src={imageUrl || NoImage} alt={name} />
      </div>
      <span>{subCategoryName}</span>
      <span>{name}</span>
      <div>
        <div>
          <MdAdd />
        </div>
        <span>Detalhes</span>
      </div>
    </Container>
  );
};

export default ProductResume;
