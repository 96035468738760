import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

// utils, services, hooks
import api from '../services/api';
import { useToast } from './toast';

// interfaces
interface AuthState {
  token: string;
}

interface SignInCredentials {
  user: string;
  password: string;
}

interface AuthContextData {
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Casem:token');

    const state = {} as AuthState;

    if (token) {
      state.token = token;

      api.defaults.headers.Authorization = `Bearer ${token}`;
    }

    return state;
  });

  const signIn = useCallback(
    async ({ user, password }) => {
      try {
        const response = await api.post('/admin/authenticate', {
          username: user,
          password,
        });

        if (response.status === 200) {
          const { token } = response.data;

          api.defaults.headers.Authorization = `Bearer ${token}`;

          setData({ token } as AuthState);

          history.push('/adm');

          localStorage.setItem('@Casem:token', token);
        } else {
          throw new Error('Erro ao logar');
        }
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro ao logar',
          description: 'Verifique o usuário e senha e tente novamente',
        });
      }
    },
    [addToast, history],
  );

  const signOut = useCallback(() => {
    api.defaults.headers.Authorization = undefined;

    setData({} as AuthState);

    localStorage.removeItem('@Casem:token');
  }, []);

  useEffect(() => {
    const id = api.interceptors.response.use(value => {
      if (value.status === 401) {
        signOut();
      }
      return value;
    });

    return () => {
      api.interceptors.response.eject(id);
    };
  }, [data, signOut]);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
