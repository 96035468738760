import styled from 'styled-components';

import breakpoints from '../../../utils/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1232px;
  margin: 32px auto 0;
  padding: 0 16px;
`;

export const UnitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;

  & > img {
    width: 90%;
    margin: 0 auto;
  }

  & > div {
    width: 90%;
    margin: 32px auto 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    & > img {
      width: 42%;
      align-self: center;
      margin: 0;
    }

    & > div {
      width: 55%;
      margin: 0;
    }

    &:nth-child(even) {
      > img {
        order: 2;
      }
      > div {
        order: 1;
      }
    }
  }
`;

export const UnitInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2 {
    text-align: center;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;

    &:before {
      border-top: 1px solid #000;
      content: '';
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    span {
      background: #fff;
      padding: 0 16px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    h2 {
      text-align: left;
      span {
        padding: 0 16px 0 0;
      }
    }
  }

  > div:nth-of-type(1),
  > div:nth-of-type(2) {
    display: flex;
    align-items: center;

    margin: 16px 0;

    svg {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    p {
      white-space: pre-line;
    }
  }
`;

export const MapContainer = styled.div`
  margin-top: auto;
  width: 100%;

  iframe {
    width: 100%;
  }
`;
