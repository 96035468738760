import styled from 'styled-components';

import breakpoints from '../../../utils/breakpoints';

export const Container = styled.div`
  padding: 32px 0;

  > div {
    > div {
      max-width: 1232px;
      margin: 0 auto;
    }
  }
`;

export const CompanyContainer = styled.div`
  padding: 0 32px;

  @media (min-width: ${breakpoints.xl}) {
    padding: 0;
  }

  img {
    width: 90%;
    margin: 0 auto;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      width: 90%;
      margin: 32px auto 0;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    img {
      width: 42%;
      margin: 0;
    }

    > div > div {
      width: 55%;
      margin: 0;
    }
  }

  h2 {
    margin-bottom: 16px;
    position: relative;
    z-index: 1;

    &:before {
      border-top: 1px solid #000;
      content: '';
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    span {
      background: #fff;
      padding: 0 16px 0 0;
    }
  }

  p {
    white-space: pre-line;
  }
`;

export const MissionVisionValuesContainer = styled.div`
  margin-top: 24px;
  background-color: #0065b3;
  color: #fff;
  padding: 16px 0;

  > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding: 16px 32px;

    @media (min-width: ${breakpoints.lg}) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }

    @media (min-width: ${breakpoints.xl}) {
      padding: 16px;
    }

    > div {
      width: 100%;
      margin: 16px 0;

      h4 {
        text-align: center;
        font-size: 1.25rem;
        margin-bottom: 16px;
        font-weight: 500;
        position: relative;
        z-index: 1;

        &:before {
          border-top: 1px solid #fff;
          content: '';
          margin: 0 auto;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
        }

        span {
          background-color: #0065b3;
          padding: 0 16px;
        }
      }

      p {
        white-space: pre-line;
      }
    }
  }
`;

export const PhotosContainer = styled.div`
  margin-top: 24px;
  padding: 0 32px;

  @media (min-width: ${breakpoints.xl}) {
    padding: 0;
  }

  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;

    &:before {
      border-top: 1px solid #000;
      content: '';
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    span {
      background: #fff;
      padding: 0 16px;
    }
  }

  > div {
    margin-top: 16px;
    flex-direction: column;

    > div {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      grid-gap: 8px 16px;

      @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: ${breakpoints.lg}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      img {
        width: 100%;
      }
    }
  }
`;
