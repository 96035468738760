import React, { useState, useCallback, useEffect } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

// components
import ProductResume from '../ProductResume';

// styles
import { Container } from './styles';

// interfaces
interface Item {
  id: number;
  image?: string;
  name: string;
  subCategoryId: number;
  subCategoryName: string;
}

interface ProductsCarouselProps {
  name: string;
  items: Item[];
}

const ProductsCarousel: React.FC<ProductsCarouselProps> = ({ name, items }) => {
  const [moveCount, setMoveCount] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemsVisibleCount, setItemsVisibleCount] = useState(0);

  const handleMoveCount = useCallback(
    (value: number) => {
      if (value < 0) setMoveCount(0);
      else if (value + itemsVisibleCount > items.length) {
        setMoveCount(items.length - itemsVisibleCount);
      } else setMoveCount(value);
    },
    [items.length, itemsVisibleCount],
  );

  useEffect(() => {
    const handler = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 1200) {
      setItemsVisibleCount(4);
    } else if (windowWidth >= 992) {
      setItemsVisibleCount(3);
    } else if (windowWidth >= 768) {
      setItemsVisibleCount(2);
    } else {
      setItemsVisibleCount(1);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (moveCount + itemsVisibleCount > items.length) {
      setMoveCount(
        items.length < itemsVisibleCount ? 0 : items.length - itemsVisibleCount,
      );
    }
  }, [items.length, itemsVisibleCount, moveCount]);

  return (
    <Container
      itemsCount={items.length}
      moveCount={moveCount}
      itemsVisibleCount={itemsVisibleCount}
    >
      <h3>
        <span>{name}</span>
      </h3>
      <div>
        <button
          type="button"
          onClick={() => handleMoveCount(moveCount - 1)}
          disabled={moveCount === 0 || items.length <= itemsVisibleCount}
        >
          <MdKeyboardArrowLeft />
        </button>
        <div>
          <div>
            {items.map(item => (
              <ProductResume key={item.id} {...item} float autoMargin />
            ))}
          </div>
        </div>
        <button
          type="button"
          onClick={() => handleMoveCount(moveCount + 1)}
          disabled={
            moveCount === items.length - itemsVisibleCount ||
            items.length <= itemsVisibleCount
          }
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </Container>
  );
};

export default ProductsCarousel;
