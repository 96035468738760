import React from 'react';

import { Container } from './styles';
import ProductResume from '../../../components/ProductResume';

interface ProductsProps {
  products: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
}

const Products: React.FC<ProductsProps> = ({ products }) => {
  return (
    <Container>
      {products.map(product => (
        <ProductResume key={product.id} {...product} />
      ))}
    </Container>
  );
};

export default Products;
