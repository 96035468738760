import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

// utils
import { MdKeyboardArrowUp } from 'react-icons/md';
import { useProductInfo } from '../../../hooks/productInformation';
import informations from '../../../utils/informations';
import { normalizeLinkText } from '../../../utils/normalizeLink';

// styles
import { WhatsAppContainer, UpContainer } from './styles';

const GlobalElements: React.FC = () => {
  const [showUp, setShowUp] = useState(false);

  const { product } = useProductInfo();

  const checkScrollTop = useCallback(() => {
    if (!showUp && window.pageYOffset > 200) {
      setShowUp(true);
    } else if (showUp && window.pageYOffset <= 200) {
      setShowUp(false);
    }
  }, [showUp]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);

    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  const whatsAppLink = useMemo(() => {
    if (!product) return informations.telephoneLink;

    const message = `Olá, estou com dúvida referente ao produto *${product.name}*
(${product.link})`;

    return `${informations.telephoneLink}&text=${normalizeLinkText(message)}`;
  }, [product]);

  return (
    <>
      <WhatsAppContainer
        href={whatsAppLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </WhatsAppContainer>
      <UpContainer
        show={Number(showUp)}
        type="button"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <MdKeyboardArrowUp />
      </UpContainer>
    </>
  );
};

export default GlobalElements;
