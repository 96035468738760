import styled from 'styled-components';
import breakpoints from '../../../utils/breakpoints';

export const Container = styled.footer`
  > div:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 32px;

    background-color: #0a2d7a;
    color: #fff;

    @media (min-width: ${breakpoints.md}) {
      flex-direction: row;
      align-items: flex-start;
    }

    img {
      display: block;
      height: 60px;

      margin: 0 auto 32px;

      @media (min-width: ${breakpoints.lg}) {
        display: none;
      }
    }

    h5 {
      display: block;
      margin-bottom: 8px;
      font-size: 1rem;
      color: #8be32f;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      width: 100%;

      @media (min-width: ${breakpoints.lg}) {
        width: unset;
        margin-bottom: 0px;

        + div {
          margin-left: 54px;
        }
      }

      > div {
        + div {
          margin-top: 16px;
        }
      }

      a + a {
        margin-top: 8px;
      }

      span {
        display: flex;
        align-items: center;

        font-size: 1rem;

        svg {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
  }

  > div:nth-child(2) {
    width: 100%;
    background-color: #202124;
    color: #fff;
    padding: 16px;
    text-align: center;

    a {
      transition: color 0.3s;
      font-weight: bold;

      &:hover {
        color: rgb(0, 101, 179);
      }
    }
  }
`;
