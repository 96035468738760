import styled from 'styled-components';
import { shade } from 'polished';

import breakpoints from '../../../utils/breakpoints';

export const Container = styled.header`
  width: 100%;
  background-color: #fff;
  z-index: 999;
  position: absolute;
`;

export const TopBar = styled.div`
  width: 100%;
  background-color: #0a2d7a;

  > div {
    display: flex;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    padding: 8px;
    font-size: 14px;

    svg {
      fill: #fff;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    > a {
      display: flex;
      align-items: center;

      &:nth-child(1) {
      }

      &:nth-child(3) {
        margin-left: auto;
      }

      &:nth-child(4) {
        margin-left: 16px;
      }

      > span {
        display: none;

        @media (min-width: ${breakpoints.md}) {
          display: block;
        }
      }
    }

    > span {
      display: none;

      @media (min-width: ${breakpoints.lg}) {
        display: block;
        margin-left: 32px;
      }
    }
  }
`;

export const Middle = styled.header`
  width: 100%;
  background-color: #fff;
  z-index: 999;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;

    a {
      &:nth-of-type(1) {
        img {
          height: 60px;
          margin-right: 16px;

          @media (min-width: ${breakpoints.lg}) {
            height: 80px;
          }
        }
      }

      &:nth-of-type(2) {
        img {
          display: none;

          @media (min-width: ${breakpoints.xl}) {
            display: block;
            height: 45px;
            margin-left: 16px;
          }
        }
      }
    }

    > div {
      margin: 0 auto;
      @media (min-width: ${breakpoints.xl}) {
        margin: 0;
      }

      div {
        + div {
          margin-top: 16px;
        }
      }
    }

    a {
      font-size: 16px;
      font-weight: 500;

      & + a {
        margin-left: 16px;
      }

      &:last-of-type {
        color: #0065b3;
      }
    }
  }
`;

export const Menu = styled.nav`
  font-family: 'Gobold', sans-serif;
  font-size: 14px;
  color: #fff;
  background-color: #0065b3;

  > div {
    display: flex;
    justify-content: center;

    > ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 64px;

      > li {
        position: relative;

        > a {
          padding: 8px 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        svg {
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }

        div {
          position: absolute;
          display: none;
          top: 100%;
          left: 0;
          color: #fff;
          background-color: ${shade(0.15, '#0065b3')};
          width: 200px;
          padding: 8px;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
          z-index: 200;
          max-height: 50vh;
          overflow-y: auto;

          ul {
            li {
              a {
                display: block;
                width: 100%;
                padding: 8px;

                &:hover {
                  color: #8be32f;
                }
              }
            }
          }
        }

        &:hover {
          color: #8be32f;
          background-color: ${shade(0.15, '#0065b3')};

          div {
            display: block;
          }
        }
      }
    }
  }
`;
