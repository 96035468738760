import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';

// components
import Route from './Route';
import Loading from '../components/Loading';

// pages
import Main from '../pages/Main';
import Home from '../pages/Home';
import Institutional from '../pages/Institutional';
import Product from '../pages/Product';
import ProductsList from '../pages/ProductsList';
import NotFound from '../pages/Main/NotFound';

// admin
const AdminLogin = React.lazy(() => import('../pages/Admin/Login'));
const AdminRoutes = React.lazy(() => import('./admin'));

const Routes: React.FC = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path="/adm/login" exact component={AdminLogin} />
      <Route path="/adm" isPrivate component={AdminRoutes} />
      <Route
        path="/"
        component={() => (
          <Main>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/produto/:idProduct" exact component={Product} />
              <Route
                path="/categoria/:idCategory"
                exact
                component={() => <ProductsList listType="category" />}
              />
              <Route
                path="/categoria/:idCategory/subcategoria/:idSubCategory"
                exact
                component={() => <ProductsList listType="subcategory" />}
              />
              <Route
                path="/buscar/:search"
                exact
                component={() => <ProductsList listType="search" />}
              />
              <Route path="/quem-somos" component={Institutional} />
              <Route path="/nossas-unidades" component={Institutional} />
              <Route path="/regioes-que-atendemos" component={Institutional} />
              <Route component={NotFound} />
            </Switch>
          </Main>
        )}
      />
    </Switch>
  </Suspense>
);

export default Routes;
