import React, { useMemo } from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Container } from './styles';

interface NavigationProps {
  pagesCount: number;
  currentPage: number;
  getData(id: number): void;
}

const Navigation: React.FC<NavigationProps> = ({
  pagesCount,
  currentPage,
  getData,
}) => {
  const pages = useMemo(() => {
    if (!pagesCount || !currentPage) return [];

    const maxPages = pagesCount < 5 ? pagesCount : 5;

    let start = currentPage - 2;
    if (start < 1) start = 1;

    if (start + maxPages > pagesCount) {
      start -= start + maxPages - pagesCount - 1;
    }

    return Array.from({ length: maxPages }, (v, i) => i + start);
  }, [pagesCount, currentPage]);

  return (
    <Container activePage={pages.findIndex(page => page === currentPage) + 1}>
      <button
        type="button"
        onClick={() => getData(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <MdKeyboardArrowLeft />
      </button>
      {pages.map(page => (
        <button key={page} type="button" onClick={() => getData(page)}>
          {page}
        </button>
      ))}
      <button
        type="button"
        onClick={() => getData(currentPage + 1)}
        disabled={currentPage === pagesCount}
      >
        <MdKeyboardArrowRight />
      </button>
    </Container>
  );
};

export default Navigation;
