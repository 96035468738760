import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { FiAlertCircle } from 'react-icons/fi';
import { Helmet } from 'react-helmet';

// components
import Path from './Path';
import Products from './Products';
import Navigation from './Navigation';

// utils, services, hooks
import api from '../../services/api';

// styles
import {
  Container,
  LoaderContainer,
  SubCategoriesContainer,
  ErrorContainer,
} from './styles';

// interfaces
interface ProductsListProps {
  listType: 'category' | 'subcategory' | 'search';
}

interface ProductsListStateToCategory {
  type: 'category';
  subCategories: {
    id: number;
    name: string;
  }[];
  products: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
  info: {
    currentPage: number;
    nextPage: number;
    pageSize: number;
    hasMore: boolean;
    pagesCount: number;
    productsCount: number;
    path: {
      categoryId: number;
      category: string;
    };
  };
}

interface ProductsListStateToSubCategory {
  type: 'subcategory';
  products: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
  info: {
    currentPage: number;
    nextPage: number;
    pageSize: number;
    hasMore: boolean;
    pagesCount: number;
    productsCount: number;
    path: {
      categoryId: number;
      category: string;
      subCategoryId: number;
      subCategory: string;
    };
  };
}

interface ProductsListStateToSearch {
  type: 'search';
  products: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
  info: {
    currentPage: number;
    nextPage: number;
    pageSize: number;
    hasMore: boolean;
    pagesCount: number;
    productsCount: number;
    path: { search: string };
  };
}

const calcItemsPerColumn = (width: number) => {
  if (width >= 1200) return 5;
  if (width >= 992) return 4;
  if (width >= 768) return 3;
  return 2;
};

const ProductsList: React.FC<ProductsListProps> = ({ listType }) => {
  const [state, setState] = useState<
    | ProductsListStateToCategory
    | ProductsListStateToSubCategory
    | ProductsListStateToSearch
    | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemsPerColumn, setItemsPerColumn] = useState(
    calcItemsPerColumn(window.innerWidth),
  );

  useEffect(() => {
    const handler = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  useEffect(() => {
    setItemsPerColumn(calcItemsPerColumn(windowWidth));
  }, [windowWidth]);

  const { idCategory, idSubCategory, search } = useParams();

  const getData = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      setError('');

      try {
        let link = '';
        const pagination = `page=${page}&pageSize=${itemsPerColumn * 5}`;

        if (listType === 'category')
          link = `/pages/category/${idCategory}?${pagination}`;
        else if (listType === 'subcategory')
          link = `/pages/subcategory/${idSubCategory}?${pagination}`;
        else if (listType === 'search')
          link = `/pages/search?q=${search}&${pagination}`;

        const response = await api.get(link);

        if (response.status === 200) {
          setState({ ...response.data, type: listType });
        } else if (response.status === 404) {
          if (listType === 'category')
            setError(`A categoria que você está procurando não existe`);
          else if (listType === 'subcategory')
            setError(`A subcategoria que você está procurando não existe`);
          else if (listType === 'search') setError(`Nenhum produto encontrado`);
        } else {
          setError('Ocorreu um erro inesperado. Tente novamente mais tarde');
        }
      } finally {
        setIsLoading(false);
      }
    },
    [idCategory, idSubCategory, itemsPerColumn, listType, search],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const pathItems = useMemo(() => {
    const items = [{ link: '/', name: 'Página Inicial' }];

    if (!state) return items;

    switch (state.type) {
      case 'category':
        return [
          ...items,
          {
            link: `/categoria/${state.info.path.categoryId}`,
            name: state.info.path.category,
          },
        ];

      case 'subcategory':
        return [
          ...items,
          {
            link: `/categoria/${state.info.path.categoryId}`,
            name: state.info.path.category,
          },
          {
            link: `/categoria/${state.info.path.categoryId}/subcategoria/${state.info.path.subCategoryId}`,
            name: state.info.path.subCategory,
          },
        ];

      case 'search':
        return [
          ...items,
          {
            link: `/search/${state.info.path.search}`,
            name: state.info.path.search,
          },
        ];

      default:
        return items;
    }
  }, [state]);

  const title = useMemo(() => {
    if (state) {
      if (state.type === 'category') return state.info.path.category;
      if (state.type === 'subcategory') return state.info.path.subCategory;
      if (state.type === 'search')
        return `Você pesquisou por ${state.info.path.search}`;
    }

    return '';
  }, [state]);

  const headerTitle = useMemo(() => {
    if (state) {
      if (state.type === 'category') return state.info.path.category;
      if (state.type === 'subcategory') return state.info.path.subCategory;
      if (state.type === 'search') return state.info.path.search;
    }

    return '';
  }, [state]);

  const description = useMemo(() => {
    if (state) {
      if (state.type === 'category')
        return `Produtos da categoria ${state.info.path.category}`;
      if (state.type === 'subcategory')
        return `Produtos da subcategoria ${state.info.path.subCategory}`;
      if (state.type === 'search')
        return `Produtos referentes a pesquisa por '${state.info.path.search}'`;
    }

    return '';
  }, [state]);

  return (
    <Container>
      {state && (
        <>
          <Helmet>
            <title>Casa das Embalagens - {headerTitle}</title>
            <meta
              property="og:title"
              content={`Casa das Embalagens - ${headerTitle}`.slice(0, 56)}
            />
            <meta name="description" content={description.slice(0, 66)} />
            <meta
              property="og:description"
              content={description.slice(0, 156)}
            />
          </Helmet>
          <Path items={pathItems} />
          <h2>{title}</h2>
          <span>{state.info.productsCount} itens</span>
          {state.type === 'category' && (
            <SubCategoriesContainer>
              {state.subCategories.map(subCategory => (
                <Link
                  key={subCategory.id}
                  to={`/categoria/${state.info.path.categoryId}/subcategoria/${subCategory.id}`}
                >
                  {subCategory.name}
                </Link>
              ))}
            </SubCategoriesContainer>
          )}
          <Products products={state.products} />
          {state.info.productsCount !== 0 && (
            <Navigation
              currentPage={state.info.currentPage}
              pagesCount={state.info.pagesCount}
              getData={getData}
            />
          )}
          {state.info.productsCount === 0 && (
            <h4>Nenhum produto foi encontrado</h4>
          )}
        </>
      )}
      {isLoading && (
        <LoaderContainer>
          <Loader color="#0065b3" width={48} type="ThreeDots" />
        </LoaderContainer>
      )}
      {error && (
        <ErrorContainer>
          <FiAlertCircle />
          <span>{error}</span>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default ProductsList;
