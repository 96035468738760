import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  margin: 16px auto;
  background: ${transparentize(0.8, '#0065b3')};
  padding: 32px 64px;
  border-radius: 5px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);

  color: #0a2d7a;

  svg {
    width: 36px;
    height: 36px;
    margin-right: 24px;
  }

  span {
    font-size: 1.2rem;
  }
`;
