import React, { useState, useCallback, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Loader from 'react-loader-spinner';
import Helmet from 'react-helmet';

// components
import ProductsCarousel from '../../components/ProductsCarousel';

// utils, services, hooks
import informations from '../../utils/informations';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

// assets
import FacebookIcon from '../../assets/facebook-icon.png';
import InstagramIcon from '../../assets/instagram-icon.png';
import MercadoLivreIcon from '../../assets/mercado-icon.png';

// styles
import {
  Container,
  CarouselContainer,
  SocialMedias,
  ContentContainer,
  LoaderContainer,
} from './styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

// interfaces
interface HomeState {
  gallery: {
    imageUrl: string;
    destinationUrl: string;
  }[];
  launches: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
  featuredes: {
    id: number;
    name: string;
    image?: string;
    subCategoryId: number;
    subCategoryName: string;
  }[];
}

const Home: React.FC = () => {
  const [state, setState] = useState<HomeState | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const { addToast } = useToast();

  const getData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await api.get('/pages/main');

      if (response.status === 200) {
        setState(response.data);
      } else throw new Error('Erro ao recuperar informações');
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro ao recuperar informações',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Container>
      {state && (
        <>
          <Helmet>
            <title>Casa das Embalagens</title>
            <meta property="og:title" content="Casa das Embalagens" />
          </Helmet>
          <CarouselContainer>
            <Carousel
              showArrows
              autoPlay
              infiniteLoop
              showThumbs={false}
              transitionTime={1000}
              interval={3000}
              showStatus={false}
              emulateTouch
            >
              {state.gallery.map(({ imageUrl, destinationUrl }, index) => (
                <a href={destinationUrl}>
                  <img
                    key={imageUrl}
                    src={imageUrl}
                    alt={`Imagem do carrosel #${index}`}
                  />
                </a>
              ))}
            </Carousel>
          </CarouselContainer>
          <SocialMedias>
            <a
              href={informations.instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={InstagramIcon} alt="Instagram" />
                <div>
                  <span>Siga-nos no Instagram</span>
                  <span>{informations.instagram}</span>
                </div>
              </div>
            </a>
            <a
              href={informations.facebookLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={FacebookIcon} alt="Facebook" />
                <div>
                  <span>Siga-nos no Facebook</span>
                  <span>{informations.facebook}</span>
                </div>
              </div>
            </a>
            <a
              href={informations.mercadoLivreLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={MercadoLivreIcon} alt="Mercado Livre" />
                <div>
                  <span>Visite nossa loja oficial no</span>
                  <span>Mercado Livre</span>
                </div>
              </div>
            </a>
          </SocialMedias>
          <ContentContainer>
            <ProductsCarousel name="Lançamentos" items={state.launches} />
            <ProductsCarousel name="Destaques" items={state.featuredes} />
          </ContentContainer>
        </>
      )}
      {isLoading && (
        <LoaderContainer>
          <Loader color="#0065b3" width={48} type="ThreeDots" />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default Home;
