import React, { useRef, useState, useCallback } from 'react';
import { MdSearch } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

interface SearchInputProps {
  style?: React.CSSProperties;
}

const SearchInput: React.FC<SearchInputProps> = ({ style }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);

  const history = useHistory();

  const handleSearch = useCallback(async () => {
    const value = inputRef?.current?.value;

    if (value) history.push(`/buscar/${value}`);
  }, [history]);

  return (
    <Container isFocus={Number(isFocus)} style={style}>
      <input
        ref={inputRef}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onKeyPress={e => e.charCode === 13 && handleSearch()}
      />
      <MdSearch onClick={handleSearch} />
    </Container>
  );
};

export default SearchInput;
