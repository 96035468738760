import styled from 'styled-components';
import { transparentize } from 'polished';
import breakpoints from '../../utils/breakpoints';

export const Container = styled.div`
  margin: 0 auto;

  width: 90%;

  /* 4 */
  /* 5 */
  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
    max-width: 1400px;
  }

  > h2 {
    display: block;
    margin: 0 16px;
  }

  > span {
    display: block;
    color: #a5a5a5;
    margin: 8px 16px 0;
  }

  > h4 {
    margin: 16px 16px 48px;
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const SubCategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 16px;

  a {
    margin: 0 8px 8px;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  margin: 16px auto;
  background: ${transparentize(0.8, '#0065b3')};
  padding: 32px 64px;
  border-radius: 5px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);

  color: #0a2d7a;

  svg {
    width: 36px;
    height: 36px;
    margin-right: 24px;
  }

  span {
    font-size: 1.2rem;
  }
`;
