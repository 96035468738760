import React from 'react';
import { Link } from 'react-router-dom';

import SearchInput from '../../../components/SearchInput';

import { Container } from './styles';

// assets
import LogoCasem from '../../../assets/casem-azul.png';

const HeaderFixed: React.FC = () => {
  return (
    <Container>
      <div>
        <Link to="/">
          <img src={LogoCasem} alt="Logo CASEM" />
        </Link>
        <div>
          <SearchInput />
        </div>
        <div>
          <Link to="/quem-somos">Quem somos</Link>
          <Link to="/nossas-unidades">Nossas unidades</Link>
          <Link to="/regioes-que-atendemos">Regiões que atendemos</Link>
        </div>
      </div>
    </Container>
  );
};

export default HeaderFixed;
